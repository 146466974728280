@import "variables";

// Set the brand colours on wp-login
@login-color: @brand-danger;

.login { 
    background-color: white;
    
    #loginform, #wp-auth-check {
        box-shadow: none;
    }
    /* Set the size of the login logo */
    h1 a {
        background-image: url('../img/logo-login.png');
        background-size: 150px 150px;
        width: 150px;
        height: 150px;
    }

    /* Hide the "Back to (website)" link */
    p#backtoblog {
        display: none;
    }

    /* Hide the "Log in" link on the last password page, as it's pointless*/
    &.login-action-lostpassword p#nav{
           display: none;   
    }

    /* Center align the Lost Password link */
    &.login-action-login p#nav{
           text-align: center;
    }

    input#wp-submit {
        background-color: @login-color;
        border-color: darken(@login-color, 5%);

        &:hover, &:focus {
            background-color: darken(@login-color, 10%);
            border-color: darken(@login-color, 15%);
        }
    }
        
    .message {
        border-left-color: @login-color;
    }

}
.wp-admin {
    #wp-auth-check-wrap #wp-auth-check {
        background-color: @login-color;
    }
}